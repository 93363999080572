.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container > div:first-child {
  width: 400px;
  margin: 20px 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.login-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container .form-group {
  margin-bottom: 15px;
}

.login-container label {
  font-weight: bold;
}

/* Estilos para los campos de entrada */
.login-container input[type="email"],
.login-container input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(
    100% - 22px
  ); /* Ajusta el ancho para compensar el relleno y el borde */
  margin-top: 10px;
  margin-bottom: 20px;
}

.login-container input[type="email"]:focus,
.login-container input[type="password"]:focus {
  outline: 1px solid #8d8e8e;
}

.login-container button {
  padding: 10px;
  background-color: #245a94;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.login-container button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  font-size: 14px;
}

.logo-container {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  padding-left: 10px;
  background-color: black;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
