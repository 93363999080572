.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
}

.loading-spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #007bff; /* Utilizamos el color principal de tu aplicación */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Agregamos la animación de rotación */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
