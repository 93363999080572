/* Estilos para el contenedor principal */
.user-data-container {
  display: flex;
  justify-content: space-around;
  background-color: #c1d5fb;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 10px auto;
  gap: 20px;
}

.user-no-data {
  display: flex;
  justify-content: space-around;
  background-color: #c1d5fb;
  font-weight: bold;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 10px auto;
  gap: 20px;
}

/* Estilos para cada sección de título y lista */
.ListDiv {
  flex: 1; /* Permite que cada sección ocupe el espacio disponible */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding-bottom: 10px;
  border-radius: 10px;
}

/* Estilos para los títulos */
.ListDiv h2,
.ListDiv h3 {
  margin-bottom: 20px;
  color: #007bff;
}

/* Estilos para las listas */
.ListDiv ul {
  padding-left: 0px;
  list-style: none;
  margin: 0;
}

/* Estilos para los elementos de la lista */
.ListDiv li {
  margin-bottom: 8px;
  padding-left: 10px;
  position: relative;
  color: #555;
}

/* Estilos para las marcas de posición */
.ListDiv li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .user-data-container {
    flex-direction: column;
    margin: 15px;
  }
  .ListDiv {
    flex: 1 1 100%;
  }
}
