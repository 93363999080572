.confirmation-dialog-container {
  position: fixed;
  bottom: -200px; /* Inicia fuera de la pantalla */
  left: 50%;
  transform: translateX(-50%);
  background-color: #9c2929;
  color: white;
  padding: 20px 40px;
  border-radius: 5px;
  text-align: center;
  padding-top: 10px;
  animation: slide-up 0.5s ease-out forwards;
}

.confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

@keyframes slide-up {
  from {
    bottom: -200px;
  }
  to {
    bottom: 10px;
  }
}
