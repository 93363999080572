/* Estilos para el contenedor principal */

.delete-account-container {
  background-color: #fee9e9;
  border-radius: 5px;
  padding: 20px;
  max-width: 300px;
  margin: 10px auto;
}

.delete-account-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente */
  align-items: center; /* Centra horizontalmente */
}

/* Estilos para el título */
.delete-account-container h2 {
  color: #9c2929;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

/* Estilos para el botón de eliminación */
.delete-account-container button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.deleted {
  background-color: #daffe8;
}

.deleted h2 {
  color: #1a6624;
}

.deleted button {
  background-color: #1a6624;
}
