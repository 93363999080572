.error-popup {
  position: fixed;
  bottom: -100px; /* Inicia fuera de la pantalla */
  left: 50%;
  transform: translateX(-50%);
  background-color: #9c2929;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.error-popup.show {
  animation: slide-up 0.5s ease-out forwards; /* Aplica animación de subida */
}

.error-popup.hide {
  animation: slide-down 0.5s ease-in forwards; /* Aplica animación de bajada */
}

@keyframes slide-up {
  from {
    bottom: -100px;
  }
  to {
    bottom: 25px;
  }
}

@keyframes slide-down {
  from {
    bottom: 25px;
  }
  to {
    bottom: -100px;
  }
}
